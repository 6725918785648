import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import 'moment-timezone';
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import { useSelector } from "react-redux";
import { Scrollbars } from 'react-custom-scrollbars-2';
import CommonPagination from "../commonModel/commonPagination";
import { UserDetail } from "../commonModel/getuser";
import { CommonDateTime } from "../../Api/commonDateTime";

const ReportTransactionList = () => {
  const { websocket } = useContext(WebSocketContext);
  const { search, userId, reportMessageList, reportList } = useSelector((state) => state.adminReducers);
  const [currentPage, setCurrentPage] = useState(1);
  const [comment, setComment] = useState(""); // Single comment state
  const [currentReportId, setCurrentReportId] = useState(null); // Track the report currently being edited

  const renderTrack = ({ style, ...props }) => {
    const trackStyle = {
      display: "none"
    };
    return <div style={{ ...style, ...trackStyle }} {...props} />;
  };

  const currentFunction = (page) => {
    setCurrentPage(page);
  };

  const reportDetail = (id) => {
    const report = reportList?.list?.filter((report) => report?.id === id)[0];
    return {
      title: report?.title,
    }
  }

  const updateDetail = (id, report_id) => {
    if (report_id && id) {
      let param = {
        "transmit": "broadcast",
        "url": "report_message_edit",
        "request": {
          "report_msg_id": id,
          "comment": comment, // Use the single comment state
          "report_id": report_id
        }
      };
      wsSend_request(websocket, param);
    }
  }

  useEffect(() => {
    let param = {
      "transmit": "broadcast",
      "url": "report_message_list",
      "request": {
        "limit": "20",
        "page_no": currentPage,
      }
    };
    wsSend_request(websocket, param);
  }, [websocket, currentPage, search, userId]);

  useEffect(() => {
    let param = {
      "transmit": "broadcast",
      "url": "report_list",
      "request": {
        "limit": "20",
        "page_no": currentPage,
      }
    };
    wsSend_request(websocket, param);
  }, [websocket, currentPage, search, userId]);

  return (
    <>
      <div style={{ margin: "2px" }} className="fixTableHead">
        <Scrollbars
          style={{ height: reportMessageList?.pagination?.total_records > 20 ? "calc(100vh - 200px)" : "calc(100vh - 160px)" }}
          renderView={props => <div {...props} className="view" />}
          renderTrackHorizontal={renderTrack}
          className="ScrollbarsSidebar"
        >
          <Table responsive style={{ borderTopLeftRadius: "19px", borderTopRightRadius: "19px" }}>
            <thead style={{ position: "sticky", top: "0px" }}>
              <tr className="user-management-table-heading">
                <th>Date & Time</th>
                <th>User Name</th>
                <th>Mobile Number</th>
                <th>Topic</th>
                <th>Message</th>
                <th>Comment</th>
              </tr>
            </thead>
            <tbody>
              {reportMessageList?.list.length > 0 ? (
                reportMessageList.list.map((report) => (
                  <tr key={report.id} className="user-management-table-detail">
                    <td style={{ cursor: "default" }}>
                      {CommonDateTime(report.created_at, "DD MMM YYYY", "show")}
                      <span className="ms-2">{CommonDateTime(report.created_at, "hh:mm A", "show")}</span>
                    </td>
                    <td style={{ cursor: "default" }}>{UserDetail(report?.user_id)?.userName || "-"}</td>
                    <td style={{ cursor: "default" }}>{UserDetail(report?.user_id)?.mobileNUmber || "-"}</td>
                    <td style={{ cursor: "default" }}>{reportDetail(report?.report_id)?.title}</td>
                    <td style={{ cursor: "default" }}>{report.message || "-"}</td>
                    <td style={{ cursor: "default" }}>
                      <span style={{ position: "relative" }}>
                        <textarea
                          onChange={(e) => setComment(e.target.value)} 
                          value={currentReportId === report.id ? comment : report.comment || ""} 
                          onFocus={() => {
                            setComment(report.comment || ""); // Set comment to existing value when focusing
                            setCurrentReportId(report.id); // Set the current report being edited
                          }}
                        ></textarea>
                      </span>
                      <span style={{ position: "absolute" }}>
                        <button
                          id="button"
                          className="pt-1 ps-2 pe-2 pb-1"
                          style={{ fontSize: "13px", marginLeft: "2px" }}
                          onClick={() => updateDetail(report?.id, report?.report_id)} // Call updateDetail on button click
                        >
                          Save
                        </button>
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6">Data not found</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Scrollbars>
        {reportMessageList?.pagination?.total_records > 20 && (
          <CommonPagination
            currentPage={currentPage}
            paginationLength={reportMessageList?.pagination}
            currentFunction={currentFunction}
          />
        )}
      </div>
    </>
  );
};

export default ReportTransactionList;
