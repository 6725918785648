/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from "react";
import 'moment-timezone';
import { Table, Form } from "react-bootstrap";
import { WebSocketContext } from "../../App";
import wsSend_request from "../../Api/ws/ws_request";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CommonPagination from "../commonModel/commonPagination";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { ClearFormSetFutios } from "../../redux/actions/adminAction";

const MachineManagement = () => {
  const { websocket } = useContext(WebSocketContext);
  const { machineList, search, listStatus, ClearFormSet } = useSelector((state) => state.adminReducers);
  const navigate = useNavigate()
  
  const [currentPage, setCurrentPage] = useState(1)
  const dispatch = useDispatch()
  const renderTrack = ({ style, ...props }) => {
    const trackStyle = {
      display: "none"
    };
    return <div style={{ ...style, ...trackStyle }} {...props} />;
  };



  useEffect(() => {
    callMachineList()
  }, [websocket, currentPage, search, listStatus])

  const currentFunction = (page) => {
    setCurrentPage(page);
  };

  function callMachineList() {
    let param = {
      "transmit": "broadcast",
      "url": "machine_list",
      "request": {
        "status": listStatus === undefined || listStatus === "" ? "" : parseInt(listStatus),
        "limit": "20",
        "page_no": search ? 1 : currentPage,
        "search": search ? search : "",
        "order_by": "-updated_at"
      }
    }
    wsSend_request(websocket, param)
  }

  useEffect(() => {
    if (ClearFormSet?.url === "machine_status_update" && ClearFormSet?.action === 200) {
      callMachineList()
    }else{
      if(ClearFormSet?.url === "machine_status_update" && ClearFormSet?.action === 400){
        callMachineList()
      }
    }
    setTimeout(() => {
      dispatch(ClearFormSetFutios({
        url: "",
        action: ""
      }))
    }, 1000)
  }, [ClearFormSet, dispatch])

  function handleSwitchChange(id, isChecked) {
    let param = {
      "transmit": "broadcast",
      "url": "machine_status_update",
      "request": {
        "status": isChecked,
        "machine_id": id
      }
    }
    wsSend_request(websocket, param)
    callMachineList()
  }


  return (
    <>
      <div style={{ margin: "2px" }} className="fixTableHead">
        <Scrollbars
          style={{ height: machineList?.pagination?.total_records > 20 ? "calc(100vh - 160px)" : "calc(100vh - 125px)" }}
          renderView={props => <div {...props} className="view" />}
          renderTrackHorizontal={renderTrack}
          className="ScrollbarsSidebar"
        >
          <Table responsive style={{ borderTopLeftRadius: "19px", borderTopRightRadius: "19px" }}>
            <thead style={{ position: "sticky", top: "0px" }}>
              <tr className="user-management-table-heading">
                <th>Machine Id/Vend Id</th>
                <th>Org Id</th>
                <th>Door Number</th>
                <th>Machine Address</th>
                <th>Status</th>
                <th>Edit</th>
              </tr>
            </thead>
              <tbody>
                {machineList?.list.length > 0 ? (
                  machineList.list.map((machine) => (
                    <tr key={machine.id} className="user-management-table-detail">
                      <td style={{ cursor: "default" }}>{machine.machine_name}</td>
                      <td style={{ cursor: "default" }}>{machine.org_id}</td>
                      <td style={{ cursor: "default" }}>{machine.door_no}</td>
                      <td style={{ cursor: "default" }}>{machine.machine_address}</td>
                      <td style={{ cursor: "pointer" }}>
                        <span>
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            defaultChecked={machine.is_active}
                            onChange={(e) => handleSwitchChange(machine.door_no, e.target.checked)}
                          />
                        </span>
                      </td>
                      <td>
                        <span className="detail-icon" onClick={() => navigate("/edit-machine/" + machine.id)}>
                          <svg height="22" viewBox="0 0 50 50" width="22" xmlns="http://www.w3.org/2000/svg"><g id="Icons"><path d="m43.4 6.6a5.44 5.44 0 0 0 -7.71 0l-19.57 19.62a1 1 0 0 0 -.28.59l-.84 7.07a1 1 0 0 0 .28.82 1 1 0 0 0 .72.3h.11l7.13-.74a1 1 0 0 0 .6-.29l19.56-19.63a5.49 5.49 0 0 0 0-7.74zm-20.72 25.69-5.54.59.64-5.49 15.46-15.5 4.89 4.9zm16.86-16.91-4.89-4.91 2.45-2.47a3.53 3.53 0 0 1 4.9 0 3.47 3.47 0 0 1 0 4.9z" /><path d="m39 38a5 5 0 0 1 -5 5h-22a5 5 0 0 1 -5-5v-22a5 5 0 0 1 5-5h15v-2h-15a7 7 0 0 0 -7 7v22a7 7 0 0 0 7 7h22a7 7 0 0 0 7-7v-15h-2z" /></g></svg>
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6">Data not found</td>
                  </tr>
                )}
              </tbody>

          </Table>
        </Scrollbars>
        {machineList?.pagination?.total_records > 20 && (<CommonPagination
          currentPage={currentPage}
          paginationLength={machineList?.pagination}
          currentFunction={currentFunction}
        />)}
      </div>
    </>
  );
};

export default MachineManagement;
