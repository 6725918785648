import React, { useEffect, useState } from "react";
import { InputGroup, Form } from 'react-bootstrap'
import { useLocation, useNavigate } from "react-router-dom";
import { getFormDate, getOnsearch, getStatus, getToDate, getUserId } from "../../redux/actions/adminAction";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select'

import { ExportData } from "../commonModel/commonExport";
const Header = () => {
    const LocationURL = useLocation().pathname
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const LocationRoute = useLocation()?.pathname?.split("/")[1];
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedValue, setSelectValue] = useState("")
    const { userList, accessToken, formDate, toDate } = useSelector((state) => state.adminReducers);
    const [optionsRegion, SetOptionsRegion] = useState(() => userList?.list?.filter((elm) => elm?.is_active)?.map((item, index) => ({ key: item.id, value: item?.id, label: item?.username })));
    const [optionVal, setOptionValue] = useState("")
    console.log(SetOptionsRegion)
    const handleChange = (e) => {
        dispatch(getStatus(e.target.value))
        setSelectValue(e.target.value)
    }
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };

    const handleExport = async () => {
        // const LocationURL = "/transaction-management";
        if (LocationURL === "/transaction-management") {
            const url = `${process.env.REACT_APP_BASE_URL}tcn/export/transactions/`;
            try {
                await ExportData(url, accessToken, formDate, toDate,"transactions");
            } catch (error) {
                console.error('Error while exporting data:', error);
                // Handle error state or display error message
            }
        }
        if (LocationURL === "/user-management") {
            const url = `${process.env.REACT_APP_BASE_URL}user/export/user/`;
            try {
                await ExportData(url, accessToken, formDate, toDate,"user");
            } catch (error) {
                console.error('Error while exporting data:', error);
                // Handle error state or display error message
            }
        }
        if (LocationURL === "/machine-management") {
            const url = `${process.env.REACT_APP_BASE_URL}machine/export/machine/`;
            try {
                await ExportData(url, accessToken, formDate, toDate,"machine");
            } catch (error) {
                console.error('Error while exporting data:', error);
                // Handle error state or display error message
            }
        }
    };

    //     const handleDailyClick = () => {
    //         const todayFormatted = moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
    //         dispatch(getFormDate(todayFormatted));
    //         dispatch(getToDate(todayFormatted));
    //     };

    //     const handleWeeklyClick = () => {
    //         const startOfWeek = moment().startOf('week').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
    //         const endOfWeek = moment().endOf('day').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
    //         dispatch(getFormDate(startOfWeek));
    //         dispatch(getToDate(endOfWeek));
    //     };

    //    const handleMonthlyClick = () => {
    //     const startOfMonth = moment().startOf('month').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
    //     const endOfMonth = moment().endOf('month').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
    //     dispatch(getFormDate(startOfMonth));
    //     dispatch(getToDate(endOfMonth));
    // };

    //     const DateStartGet = (date) => {
    //         if (date) {
    //             setFromDate(date);
    //             dispatch(getFormDate(date))
    //         } else {
    //             setFromDate("");
    //             dispatch(getFormDate(""))
    //         }
    //     };

    //     const DateEndGet = (date) => {
    //         if (date === null) {
    //             setToDate("");
    //             dispatch(getToDate(""))
    //         } else {
    //             setToDate(date);
    //             dispatch(getToDate(date))
    //         }
    //     };

    //     const callHandleChange = (event) => {
    //         setSelectedOption(event.target.value);
    //         // Handle logic based on selected option
    //         switch (event.target.value) {
    //             case 'daily':
    //                 handleDailyClick();
    //                 break;
    //             case 'weekly':
    //                 handleWeeklyClick();
    //                 break;
    //             case 'monthly':
    //                 handleMonthlyClick();
    //                 break;
    //             case 'all':
    //                 handleAllClick();
    //                 break;
    //             default:
    //                 break;
    //         }
    //     };
    function getPlaceholder(){
        if(LocationURL === "/user-management"){
            return{
                name:"Search with Mobile No. or  User ID"
            }
        }
        if(LocationURL === "/payment-management"){
            return{
                name:"Search by Revenue Monster id, User ID "
            }
        }
        if(LocationURL === "/transaction-management"){
            return{
                name:"Filter by : Purchase / Refund / Transfer"
            }
        }
    }
    const RegionValueSelect = (value) => {
        if (value !== null) {
            setOptionValue(value)
            dispatch(getUserId(value?.key))
        } else {
            setOptionValue("")
            dispatch(getUserId(""))
        }
    };

    // const handleAllClick = () => {
    //     dispatch(getFormDate(""));
    //     dispatch(getToDate(""));
    // };


    useEffect(() => {
        dispatch(getOnsearch(""))
        dispatch(getStatus(""))
        setSelectValue("")
        setSearchQuery("")
        dispatch(getUserId(""))
        dispatch(getFormDate(""))
        dispatch(getToDate(""))
    }, [LocationURL, dispatch])

    return (
        <>
            <div className="row">
                <div className="col-12 ">
                    <div className="row ">
                        <div className="col-4">
                            {LocationURL === "/user-management" && <p className="header-text">User Management</p>}
                            {LocationURL === "/order-management" && <p className="header-text">Order Management</p>}
                            {LocationURL === "/machine-management" && <p className="header-text">Machine Management</p>}
                            {LocationURL === "/add-machine" && <p className="header-text">+ Machine Management</p>}
                            {LocationURL === "/contact-us" && <p className="header-text">Contact Management</p>}
                            {LocationURL === "/payment-management" && <p className="header-text">Payment Management</p>}
                            {LocationURL === "/transaction-management" && <p className="header-text">Transaction Management</p>}
                            {LocationURL === "/refund" && <p className="header-text">Refund Management</p>}
                            {LocationURL === "/page" && <p className="header-text">Pages</p>}
                            {LocationURL === "/add-faq" && <p className="header-text">+ FAQ Management</p>}
                            {LocationURL === "/add-benefit" && <p className="header-text">+ Benefits</p>}
                            {LocationURL === "/faq" && <p className="header-text">FAQ Management</p>}
                            {LocationURL === "/benefit" && <p className="header-text">Benefits </p>}
                            {LocationURL === "/banner" && <p className="header-text">Banner</p>}
                            {LocationRoute === "edit-faq" && <p className="header-text">Edit Faq</p>}
                            {LocationRoute === "edit-machine" && <p className="header-text">Edit Machine</p>}
                            {LocationURL === "/report-an-history" && <p className="header-text">Report an History</p>}
                        </div>
                        <div className="col-8">
                            <div className="row mt-2">
                                <div className={LocationURL === "/user-management"?"col-7":"col-6 "}>
                                    {(LocationURL !== "/add-machine" && LocationURL !== "/add-page" && LocationURL !== "/add-faq" && LocationRoute !== "faq" && LocationRoute !== "page" && LocationRoute !== "edit-page" && LocationRoute !== "edit-faq" && LocationRoute !== "edit-machine" && LocationURL !== "/add-benefit" && LocationURL !== "/banner") && <InputGroup className="mb-3 ">
                                        <InputGroup.Text id="inputGroup-sizing-default" style={{ backgroundColor: "#F2F2F2" }}>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_1126_461)">
                                                    <path d="M8.75 15C12.2018 15 15 12.2018 15 8.75C15 5.29822 12.2018 2.5 8.75 2.5C5.29822 2.5 2.5 5.29822 2.5 8.75C2.5 12.2018 5.29822 15 8.75 15Z" stroke="#1C1C1C" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M13.1694 13.1694L16.5 16.5" stroke="#1C1C1C" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1126_461">
                                                        <rect width="20" height="20" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </InputGroup.Text>
                                        <Form.Control
                                            aria-label="Default"
                                            placeholder={getPlaceholder()?.name}
                                            aria-describedby="inputGroup-sizing-default"
                                            style={{ height: "33px", borderLeft: "none", borderRight: "none",backgroundColor:"#F2F2F2" }}
                                            onChange={(e) => {
                                                const newText = e.target.value.replace(/[^a-zA-Z0-9]/g,'');
                                                dispatch(getOnsearch(newText.trim()))
                                                setSearchQuery(newText)
                                            }}
                                            onKeyDown={(e) => dispatch(getOnsearch(e.target.value.trim()))}
                                            value={searchQuery}
                                        />
                                        <InputGroup.Text style={{ backgroundColor: "#F2F2F2" }}>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 1.07143C0 0.479694 0.479694 0 1.07143 0H10.3571C10.9489 0 11.4286 0.479694 11.4286 1.07143C11.4286 1.66316 10.9489 2.14286 10.3571 2.14286H1.07143C0.479694 2.14286 0 1.66316 0 1.07143ZM4.64286 4.28571C4.05113 4.28571 3.57143 4.76541 3.57143 5.35714C3.57143 5.94887 4.05113 6.42857 4.64286 6.42857H10.3571C10.9489 6.42857 11.4286 5.94887 11.4286 5.35714C11.4286 4.76541 10.9489 4.28571 10.3571 4.28571H4.64286ZM7.5 8.57143C6.90827 8.57143 6.42857 9.05113 6.42857 9.64286C6.42857 10.2346 6.90827 10.7143 7.5 10.7143H10.3571C10.9489 10.7143 11.4286 10.2346 11.4286 9.64286C11.4286 9.05113 10.9489 8.57143 10.3571 8.57143H7.5ZM15.3571 0C16.1461 0 16.7857 0.639593 16.7857 1.42857V14.2857H18.9286C19.3619 14.2857 19.7526 14.5467 19.9184 14.9471C20.0843 15.3474 19.9926 15.8083 19.6861 16.1147L16.1147 19.6861C15.6963 20.1046 15.018 20.1046 14.5996 19.6861L11.0281 16.1147C10.7217 15.8083 10.63 15.3474 10.7958 14.9471C10.9617 14.5467 11.3524 14.2857 11.7857 14.2857H13.9286V1.42857C13.9286 0.639593 14.5681 0 15.3571 0Z" fill="#525252" />
                                            </svg>
                                        </InputGroup.Text>
                                    </InputGroup>}
                                </div>
                                {(LocationURL === "/transaction-management" || LocationURL === "/user-management" || LocationURL === "/machine-management") && <div className="col-2 ">
                                    <button className="pt-1 pb-1" id="button" onClick={handleExport}> <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" height={20} width={20}><g id="Export" fill="#ffff"><path d="m46 54.49h-30a6.51 6.51 0 0 1 -6.49-6.49v-30a6.51 6.51 0 0 1 6.5-6.5h14.99a1.5 1.5 0 0 1 0 3h-15a3.5 3.5 0 0 0 -3.5 3.5v30a3.5 3.5 0 0 0 3.5 3.5h30a3.5 3.5 0 0 0 3.5-3.5v-15a1.5 1.5 0 0 1 3 0v15a6.51 6.51 0 0 1 -6.5 6.49z" /><path d="m53 25.5a1.5 1.5 0 0 1 -1.5-1.5v-11.49h-11.5a1.5 1.5 0 0 1 0-3h13a1.5 1.5 0 0 1 1.5 1.5v12.99a1.5 1.5 0 0 1 -1.5 1.5z" /><path d="m33.18 32.32a1.5 1.5 0 0 1 -1.06-2.56l19.81-19.76a1.5 1.5 0 0 1 2.12 2.12l-19.81 19.76a1.49 1.49 0 0 1 -1.06.44z" /></g></svg>
                                        Export</button>
                                </div>}
                                { LocationURL === "/user-management" && <div className="col-2">
                                    <select value={selectedValue} onChange={handleChange} className="ps-3 pe-3 pb-2 pt-1" style={{backgroundColor:"#F2F2F2"}}>
                                        <option value="">All</option>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </select>
                                </div>}
                                {(LocationURL === "/transaction-management" || LocationURL === "/payment-management" || LocationURL === "/order-management") && <div className="col-3">
                                    <Select value={optionVal}
                                        onChange={(e) => RegionValueSelect(e)}
                                        // defaultValue={defaultCategoryOption} 
                                        isClearable
                                        options={optionsRegion}
                                        className="godown-select-container"
                                        classNamePrefix="godown-select"
                                        styles={customStyles}
                                        placeholder="Select User"
                                    />
                                </div>}
                                <div className="col-4 ">
                                    {LocationURL === "/machine-management" &&
                                        <button className="pt-1 pb-1 ps-3 pe-3 ms-3" id="button" onClick={() => navigate('/add-machine')}>+ Add Machine</button>
                                    }
                                    {LocationURL === "/faq" &&
                                        <button className="pt-1 pb-1 ps-4 pe-4" style={{ marginLeft: "20px" }} id="button" onClick={() => navigate('/add-faq')}>+ Add FAQ</button>
                                    }
                                    {LocationURL === "/benefit" &&
                                        <button className="pt-1 pb-1 ps-4 pe-4" style={{ marginLeft: "20px" }} id="button" onClick={() => navigate('/add-benefit')}>+ Add Benefit</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {LocationURL === "/transaction-management" && <div className="row">
                        <div className="col-2  mb-3" id="datepickField">
                            <DatePicker
                                selected={FromDate}
                                startDate={FromDate}
                                endDate={ToDate}
                                onChange={(date) => DateStartGet(date)}
                                dateFormat="dd/MM/yyyy"
                                maxDate={today}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                placeholderText="Enter From Date"
                                isClearable={(FromDate !== "" && FromDate !== null) ? true : false}
                            />
                        </div>
                        <div className="col-2" id="datepickField">
                            <DatePicker
                                selected={ToDate}
                                startDate={FromDate}
                                endDate={ToDate}
                                minDate={FromDate}
                                onChange={(date) => DateEndGet(date)}
                                dateFormat="dd/MM/yyyy"
                                maxDate={today}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                placeholderText="Enter To Date"
                                isClearable={ToDate !== "" ? true : false}
                            />
                        </div>
                        <div className="col-2">
                            <select value={selectedOption} onChange={callHandleChange} className="ps-2 pe-2 pb-2 pt-1">
                                <option value="all">All</option>
                                <option value="daily">Daily</option>
                                <option value="weekly">Weekly</option>
                                <option value="monthly">Monthly</option>
                            </select>
                        </div>
                    </div>} */}
                </div>
            </div>
        </>
    )
}


export default Header