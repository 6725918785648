
import 'react-toastify/dist/ReactToastify.css';
import { ClearFormSetFutios, addMachineList, addPageList, addbenefitList, addfaqList, clearRedux, editMachineList, editPageList, editbenefitList, editfaqList, getBannerList, getContactList, getDocTypeList, getFaqList, getMachineList, getOrderList, getPageList, getPaymentList, getRefundList, getRefundListApi, getReportList, getReportMessageList, getSingleMachineList, getTransacionList, getUserList, getbenefitList } from '../../redux/actions/adminAction';
import { toast } from 'react-toastify';
import CommonLogout from '../../component/commonModel/commonLogout';

export const ws_response = ({ evt, navigate }) => {
    return (async (dispatch, getState) => {
        const ws_onmessage = JSON.parse(evt.data);
        const { accessToken } = getState()?.adminReducers;
        if (ws_onmessage?.response?.status === 403) {
            if (accessToken !== "" && accessToken !== null) {
                CommonLogout(accessToken, dispatch);
            }
        };
        switch (ws_onmessage.url) {
            case 'user_list':
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(getUserList(ws_onmessage?.response?.data))
                } else {
                    dispatch(getUserList(ws_onmessage?.response?.data));
                };
                break
            case 'transaction_list':
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(getTransacionList(ws_onmessage?.response?.data))
                } else {
                    dispatch(getTransacionList(ws_onmessage?.response?.data));
                };
                break
            case 'payment_list':
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(getPaymentList(ws_onmessage?.response?.data))
                } else {
                    dispatch(getPaymentList(ws_onmessage?.response?.data));
                };
                break
            case 'machine_list':
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(getMachineList(ws_onmessage?.response?.data))
                } else {
                    dispatch(getMachineList(ws_onmessage?.response?.data));
                };
                break
            case 'machine_detail':
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(getSingleMachineList(ws_onmessage?.response?.data))
                } else {
                    dispatch(getSingleMachineList(ws_onmessage?.response?.data));
                };
                break
            case 'machine_add':
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addMachineList(ws_onmessage?.response?.data))
                    dispatch(ClearFormSetFutios({
                        url: "machine_add",
                        action: ws_onmessage?.response?.status
                    }))
                    toast.success(ws_onmessage?.response?.msg)
                } else {
                    toast.error(ws_onmessage?.response?.msg)
                    dispatch(addMachineList(ws_onmessage?.response?.data));
                };
                break
            case 'machine_edit':
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(editMachineList(ws_onmessage?.response?.data))
                    dispatch(ClearFormSetFutios({
                        url: "machine_edit",
                        action: ws_onmessage?.response?.status
                    }))
                    toast.success(ws_onmessage?.response?.msg)
                } else {
                    dispatch(editMachineList(ws_onmessage?.response?.data));
                    toast.error(ws_onmessage?.response?.msg)
                };
                break
            case 'page_list':
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(getPageList(ws_onmessage?.response?.data))
                } else {
                    dispatch(getPageList(ws_onmessage?.response?.data));
                };
                break
            case 'page_add':
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addPageList(ws_onmessage?.response?.data))
                    dispatch(ClearFormSetFutios({
                        url: "page_add",
                        action: ws_onmessage?.response?.status
                    }))
                    toast.success(ws_onmessage?.response?.msg)
                } else {
                    dispatch(addPageList(ws_onmessage?.response?.data));
                    toast.error(ws_onmessage?.response?.msg)
                };
                break
            case 'page_edit':
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(editPageList(ws_onmessage?.response?.data))
                    dispatch(ClearFormSetFutios({
                        url: "page_edit",
                        action: ws_onmessage?.response?.status
                    }))
                    toast.success(ws_onmessage?.response?.msg)
                } else {
                    dispatch(editPageList(ws_onmessage?.response?.data));
                };
                break
            case 'order_list':
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(getOrderList(ws_onmessage?.response?.data))
                } else {
                    dispatch(getOrderList(ws_onmessage?.response?.data));
                };
                break
            case 'contact_list':
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(getContactList(ws_onmessage?.response?.data))
                } else {
                    dispatch(getContactList(ws_onmessage?.response?.data));
                };
                break
            case 'faq_list':
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(getFaqList(ws_onmessage?.response?.data))
                } else {
                    dispatch(getFaqList(ws_onmessage?.response?.data));
                };
                break
            case 'faq_add':
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addfaqList(ws_onmessage?.response?.data))
                    dispatch(ClearFormSetFutios({
                        url: "faq_add",
                        action: ws_onmessage?.response?.status
                    }))
                    toast.success(ws_onmessage?.response?.msg)
                } else {
                    dispatch(addfaqList(ws_onmessage?.response?.data));
                    toast.error(ws_onmessage?.response?.msg)
                };
                break
            case 'faq_edit':
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(editfaqList(ws_onmessage?.response?.data))
                    dispatch(ClearFormSetFutios({
                        url: "faq_edit",
                        action: ws_onmessage?.response?.status
                    }))
                    toast.success(ws_onmessage?.response?.msg)
                } else {
                    dispatch(editfaqList(ws_onmessage?.response?.data));
                };
                break
            case 'user_status_update':
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(ClearFormSetFutios({
                        url: "user_status_update",
                        action: ws_onmessage?.response?.status
                    }))
                    toast.success(ws_onmessage?.response?.msg)
                }
                break
            case 'machine_status_update':
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(ClearFormSetFutios({
                        url: "machine_status_update",
                        action: ws_onmessage?.response?.status
                    }))
                    toast.success(ws_onmessage?.response?.msg)
                } else {
                    dispatch(ClearFormSetFutios({
                        url: "machine_status_update",
                        action: ws_onmessage?.response?.status
                    }))
                    toast.error(ws_onmessage?.response?.msg)
                }
                break
            case 'page_status_update':
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(ClearFormSetFutios({
                        url: "page_status_update",
                        action: ws_onmessage?.response?.status
                    }))
                    toast.success(ws_onmessage?.response?.msg)
                }
                break
            case 'faq_delete':
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(ClearFormSetFutios({
                        url: "faq_delete",
                        action: ws_onmessage?.response?.status
                    }))
                    toast.success(ws_onmessage?.response?.msg)
                }
                break
            case 'document_list':
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(getBannerList(ws_onmessage?.response?.data))
                } else {
                    dispatch(getBannerList(ws_onmessage?.response?.data));
                };
                break
            case 'benefit_list':
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(getbenefitList(ws_onmessage?.response?.data))
                } else {
                    dispatch(getbenefitList(ws_onmessage?.response?.data));
                };
                break
            case 'benefit_add':
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addbenefitList(ws_onmessage?.response?.data))
                    dispatch(ClearFormSetFutios({
                        url: "benefit_add",
                        action: ws_onmessage?.response?.status
                    }))
                    toast.success(ws_onmessage?.response?.msg)
                } else {
                    dispatch(addbenefitList(ws_onmessage?.response?.data));
                    toast.error(ws_onmessage?.response?.msg)
                };
                break
            case 'benefit_edit':
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(editbenefitList(ws_onmessage?.response?.data))
                    dispatch(ClearFormSetFutios({
                        url: "benefit_edit",
                        action: ws_onmessage?.response?.status
                    }))
                    toast.success(ws_onmessage?.response?.msg)
                } else {
                    dispatch(editbenefitList(ws_onmessage?.response?.data));
                };
                break
            case 'benefit_delete':
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(ClearFormSetFutios({
                        url: "benefit_delete",
                        action: ws_onmessage?.response?.status
                    }))
                    toast.success(ws_onmessage?.response?.msg)
                }
                break
            case 'refund_req_list':
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(getRefundList(ws_onmessage?.response?.data))
                } else {
                    dispatch(getRefundList(ws_onmessage?.response?.data));
                };
                break
            case 'report_list':
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(getReportList(ws_onmessage?.response?.data))
                } else {
                    dispatch(getReportList(ws_onmessage?.response?.data));
                };
                break
            case 'refund_edit':
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(ClearFormSetFutios({
                        url: "refund_edit",
                        action: ws_onmessage?.response?.status
                    }))
                    toast.success(ws_onmessage?.response?.msg)
                } else {
                    toast.success(ws_onmessage?.response?.msg)
                };
                break
            case 'report_add':
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(ClearFormSetFutios({
                        url: "report_add",
                        action: ws_onmessage?.response?.status
                    }))
                    toast.success(ws_onmessage?.response?.msg)
                } else {
                    toast.success(ws_onmessage?.response?.msg)
                };
                break
            case 'report_delete':
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(ClearFormSetFutios({
                        url: "report_delete",
                        action: ws_onmessage?.response?.status
                    }))
                    toast.success(ws_onmessage?.response?.msg)
                }
                break
            case 'doc_type_list':
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(getDocTypeList(ws_onmessage?.response?.data))
                } else {
                    dispatch(getDocTypeList(ws_onmessage?.response?.data));
                };
                break
            case 'document_type_add':
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(ClearFormSetFutios({
                        url: "document_type_add",
                        action: ws_onmessage?.response?.status
                    }))
                    toast.success(ws_onmessage?.response?.msg)
                } else {
                    toast.success(ws_onmessage?.response?.msg)
                };
                break
            case 'doc_type_delete':
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(ClearFormSetFutios({
                        url: "doc_type_delete",
                        action: ws_onmessage?.response?.status
                    }))
                    toast.success(ws_onmessage?.response?.msg)
                }
                break
            case 'report_message_list':
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(getReportMessageList(ws_onmessage?.response?.data))
                } else {
                    dispatch(getReportMessageList(ws_onmessage?.response?.data));
                };
                break
            case 'document_add':
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(ClearFormSetFutios({
                        url: "document_add",
                        action: ws_onmessage?.response?.status
                    }))
                    toast.success(ws_onmessage?.response?.msg)
                } else {
                    toast.success(ws_onmessage?.response?.msg)
                };
                break
            case 'refund_list':
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(getRefundListApi(ws_onmessage?.response?.data))
                } else {
                    dispatch(getRefundListApi(ws_onmessage?.response?.data));
                };
                break
            case 'document_delete':
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(ClearFormSetFutios({
                        url: "document_delete",
                        action: ws_onmessage?.response?.status
                    }))
                    toast.success(ws_onmessage?.response?.msg)
                }
                break
                case 'report_edit':
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(ClearFormSetFutios({
                        url: "report_edit",
                        action: ws_onmessage?.response?.status
                    }))
                    toast.success(ws_onmessage?.response?.msg)
                } else {
                    toast.success(ws_onmessage?.response?.msg)
                };
                break
                case 'document_type_edit':
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(ClearFormSetFutios({
                        url: "document_type_edit",
                        action: ws_onmessage?.response?.status
                    }))
                    toast.success(ws_onmessage?.response?.msg)
                } else {
                    toast.success(ws_onmessage?.response?.msg)
                };
                break
                case 'contact_edit':
                    console.log("ws_onmessage?.response",ws_onmessage?.response)
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(ClearFormSetFutios({
                            url: "contact_edit",
                            action: ws_onmessage?.response?.status
                        }))
                        toast.success(ws_onmessage?.response?.msg)
                    }
                break
                case 'report_message_edit':
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(ClearFormSetFutios({
                            url: "report_message_edit",
                            action: ws_onmessage?.response?.status
                        }))
                        toast.success(ws_onmessage?.response?.msg)
                    }
                break
            case "unauthorized":
                CommonLogout(accessToken, dispatch);
                dispatch(clearRedux());
                break;
            default:
                return;
        }

    })
}

