/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useEffect, useContext,useRef} from "react";
import { Form } from 'react-bootstrap'
import Scrollbars from 'react-custom-scrollbars-2'
import { useSelector } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import UploadFileAPI from '../../Api/uploadFIleApi'
import Images from "../../Api/Images";
const Banner = () => {
    const { bannerList, accessToken } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);

    const renderTrack = ({ style, ...props }) => {
        const trackStyle = {
            display: "none"
        };
        return <div style={{ ...style, ...trackStyle }} {...props} />;
    };

    function deleteBanner(id) {
        let param = {
            "transmit": "broadcast",
            "url": "document_delete",
            "request": {
                "doc_id": id,
            }
        }
        wsSend_request(websocket, param)
        callDocumentList()
    }

    const UploadImage = async (e) => {
        const files = e.target.files[0];
        const formData = new FormData();
        formData.append("file", files, files.name);
        const fileresponse = await UploadFileAPI(accessToken, formData);
        if (fileresponse?.status === 200) {
            let param = {
                "transmit": "broadcast",
                "url": "document_add",
                "request": {
                    "document_image": fileresponse?.id,
                    "title": "",
                    "sub_title": "",
                    "is_flash_screen": false,
                    "is_banner_screen": true
                }
            }
            wsSend_request(websocket, param)
            callDocumentList()
        }
    };

    useEffect(() => {
        callDocumentList()
    }, [websocket])

    const callDocumentList = () => {
        let param = {
            "transmit": "broadcast",
            "url": "document_list",
            "request": {
                "limit": "20",
                "page_no": 1,
            }
        }
        wsSend_request(websocket, param)
    }
    const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };
    
    return (
        <>
            <div style={{ margin: "2px" }} className="fixTableHead p-3 ms-2">
                <Scrollbars
                    style={{ height: "calc(100vh - 125px)" }}
                    renderView={props => <div {...props} className="view" />}
                    renderTrackHorizontal={renderTrack}
                    className="ScrollbarsSidebar"
                >
                    <Form.Group>
                        <div className="row " >
                            <div className="col-12 col-md-12 col-sm-12 col-lg-12">
                                <Form.Label className="ms-1">Upload Banner image</Form.Label>
                            </div>
                            <span className="file-upload" onClick={handleClick}>
                                <span >
                                <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.50458 7.50483C6.33301 8.6764 6.33301 10.562 6.33301 14.3332V61.6666C6.33301 65.4378 6.33301 67.3234 7.50458 68.495C8.67615 69.6666 10.5618 69.6666 14.333 69.6666H61.6663C65.4376 69.6666 67.3232 69.6666 68.4948 68.495C69.6663 67.3234 69.6663 65.4378 69.6663 61.6666V14.3333C69.6663 10.562 69.6663 8.6764 68.4948 7.50483C68.3666 7.37663 68.2298 7.26247 68.083 7.16079V53.8333H66.6467C65.0117 53.8333 64.1942 53.8333 63.4591 53.5288C62.724 53.2243 62.146 52.6462 60.9899 51.4901L53.4948 43.995C52.1614 42.6617 51.4948 41.995 50.6663 41.995C49.8379 41.995 49.1712 42.6617 47.8379 43.995L40.0885 51.7444C39.1421 52.6908 38.6689 53.164 38.1479 53.0637C37.6269 52.9633 37.3632 52.3482 36.836 51.118L30.827 37.097C29.7725 34.6366 29.2453 33.4064 28.2033 33.2057C27.1612 33.005 26.2148 33.9515 24.322 35.8443L11.083 49.0833V6.37988C9.35741 6.46983 8.27615 6.73326 7.50458 7.50483Z" fill="#525252"/>
                                    <path d="M7.33301 14.3335C7.33301 12.4196 7.33513 11.0848 7.47054 10.0777C7.60208 9.09929 7.84267 8.58119 8.21169 8.21218C8.5807 7.84316 9.0988 7.60256 10.0772 7.47103C11.0843 7.33562 12.4191 7.3335 14.333 7.3335H61.6663C63.5802 7.3335 64.915 7.33562 65.9222 7.47103C66.9005 7.60256 67.4186 7.84316 67.7877 8.21218C68.1567 8.58119 68.3973 9.09929 68.5288 10.0777C68.6642 11.0848 68.6663 12.4196 68.6663 14.3335V61.6668C68.6663 63.5807 68.6642 64.9155 68.5288 65.9227C68.3973 66.901 68.1567 67.4191 67.7877 67.7881C67.4186 68.1572 66.9005 68.3978 65.9222 68.5293C64.915 68.6647 63.5802 68.6668 61.6663 68.6668H14.333C12.4191 68.6668 11.0843 68.6647 10.0772 68.5293C9.0988 68.3978 8.58071 68.1572 8.21169 67.7881C7.84267 67.4191 7.60208 66.901 7.47054 65.9227C7.33513 64.9155 7.33301 63.5807 7.33301 61.6668V14.3335Z" stroke="#525252" stroke-width="2"/>
                                    <circle cx="47.5003" cy="28.4998" r="6.33333" fill="#525252"/>
                                    </svg>
                                </span>
                                <span className="text-banner-upload" >file Uploads</span>
                                <span className="subtitle-text-banner-upload">
                                    JPEG/PNG
                                </span>
                                {/* <input
                                    type="file"
                                    id="fileInput"
                                    onChange={(e) => UploadImage(e)}
                                    accept=".png, .jpg, .pdf"
                                /> */}
                                      <input type="file" onChange={UploadImage} ref={fileInputRef} accept=".png, .jpg, .pdf"/>
                            </span>
                          
                            {/* <label className="file-upload" htmlFor="fileInput">
                                    <span className="image-color">
                                        <span>
                                            <svg width="25" height="25" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_1522_1249)">
                                                    <path d="M25.1804 12.6466C24.9955 7.87516 21.0305 4.0625 16.2561 4.0625C14.5952 4.06276 12.9672 4.52528 11.5543 5.39828C10.1414 6.27129 8.99935 7.52032 8.25602 9.00555C7.63821 10.2369 7.3152 11.595 7.3125 12.9726C7.31568 13.1819 7.23972 13.3847 7.09981 13.5404C6.9599 13.6962 6.76636 13.7933 6.55789 13.8125C6.44642 13.8205 6.33451 13.8053 6.22915 13.7681C6.1238 13.7308 6.02725 13.6723 5.94556 13.596C5.86387 13.5197 5.79879 13.4275 5.75439 13.3249C5.70999 13.2224 5.68722 13.1117 5.6875 13C5.68664 11.8636 5.86979 10.7345 6.22984 9.65656C6.25357 9.58745 6.25821 9.51321 6.24328 9.44168C6.22835 9.37015 6.1944 9.30397 6.14502 9.25011C6.09563 9.19625 6.03263 9.15671 5.96266 9.13566C5.89269 9.1146 5.81833 9.11281 5.74742 9.13047C4.33959 9.48137 3.08934 10.2926 2.19525 11.4353C1.30116 12.5779 0.81448 13.9866 0.8125 15.4375C0.8125 19.0115 3.83398 21.9375 7.41406 21.9375H16.25C17.4531 21.9362 18.6435 21.6923 19.7501 21.2203C20.8568 20.7484 21.8568 20.0581 22.6905 19.1907C23.5242 18.3233 24.1744 17.2968 24.6022 16.1723C25.0301 15.0479 25.2267 13.8487 25.1804 12.6466ZM19.2623 16.8248L16.0123 20.0748C15.9369 20.1504 15.8473 20.2103 15.7486 20.2512C15.65 20.2921 15.5443 20.3131 15.4375 20.3131C15.3307 20.3131 15.225 20.2921 15.1264 20.2512C15.0277 20.2103 14.9381 20.1504 14.8627 20.0748L11.6127 16.8248C11.4602 16.6724 11.3745 16.4656 11.3745 16.25C11.3745 16.0344 11.4602 15.8276 11.6127 15.6752C11.7651 15.5227 11.9719 15.437 12.1875 15.437C12.4031 15.437 12.6099 15.5227 12.7623 15.6752L14.625 17.5388V11.375C14.625 11.1595 14.7106 10.9528 14.863 10.8005C15.0153 10.6481 15.222 10.5625 15.4375 10.5625C15.653 10.5625 15.8597 10.6481 16.012 10.8005C16.1644 10.9528 16.25 11.1595 16.25 11.375V17.5388L18.1127 15.6752C18.2651 15.5227 18.4719 15.437 18.6875 15.437C18.9031 15.437 19.1099 15.5227 19.2623 15.6752C19.4148 15.8276 19.5005 16.0344 19.5005 16.25C19.5005 16.4656 19.4148 16.6724 19.2623 16.8248Z" fill="white" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1522_1249">
                                                        <rect width="26" height="26" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </span>
                                    </span>
                                    <span className="upload-file-text">Upload Files</span>
                                    <span >JPEG, PNG</span>
                                    <input
                                        type="file"
                                        id="fileInput"
                                        onChange={(e) => UploadImage(e)}
                                        accept=".png, .jpg, .pdf"
                                    />
                                </label> */}
                            {/* </div> */}

                        </div>

                    </Form.Group>
                    <div className="row " style={{ marginLeft: "0px" }}>
                        <div className="col-12">
                            <div className="row">
                                {bannerList.list.map((banner, index) => (
                                    <div key={banner.id} className="col-4 mt-3">
                                        <span style={{ position: "relative" }}>
                                            <Images src={banner.document_image} height={140} width={305} alt="Banner"/>
                                            <span className="remove-icon" onClick={() => deleteBanner(banner.id)}><svg width="20" height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_1522_1263)">
                                                    <path d="M10.4805 3.51953L3.51953 10.4805" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M10.4805 10.4805L3.51953 3.51953" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1522_1263">
                                                        <rect width="100" height="100" fill="white" transform="translate(0.8125 0.8125)" />
                                                    </clipPath>
                                                </defs>
                                            </svg></span>
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </Scrollbars>

            </div>
        </>
    );
};

export default Banner;
