/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import 'moment-timezone';
import { Form } from "react-bootstrap";
import CheckValid from "../../CheckValid/checkValid";
import { useLocation, useNavigate } from "react-router-dom";
import wsSend_request from "../../Api/ws/ws_request";
import { useContext } from "react";
import { WebSocketContext } from "../../App";
import { useDispatch, useSelector } from "react-redux";
import { ClearFormSetFutios } from "../../redux/actions/adminAction";

import {
    EditorState,
    convertToRaw,
    convertFromHTML,
    ContentState
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


const AboutPage = ({tabName}) => {
    const LocationRoute = useLocation()?.pathname?.split("/")[1];
    const { websocket } = useContext(WebSocketContext);
    const { ClearFormSet, pageList } = useSelector((state) => state.adminReducers);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [createPage, setCreatePage] = useState({
        page_name: "",
        page_slug: "",
        page_description: "",
        active: true
    })
    const [questionEditorState, setAnsEditorState] = useState(
        EditorState.createEmpty()
    );
    const [charCount, setCharCount] = useState("")
    const PageListData = pageList?.list?.filter((data) => data.page_slug === "about-us")[0]
    const [errorDescription, setDesciptionError] = useState("")

    useEffect(() => {
        if (ClearFormSet?.url === "page_add" && ClearFormSet?.action === 200) {
            navigate('/page')
        }
        setTimeout(() => {
            dispatch(ClearFormSetFutios({
                url: "",
                action: ""
            }))
        }, 1000)
    }, [ClearFormSet, dispatch, navigate])

 
    useEffect(() => {
        if (ClearFormSet?.url === "page_edit" && ClearFormSet?.action === 200) {
            navigate('/page')
        }
        setTimeout(() => {
            dispatch(ClearFormSetFutios({
                url: "",
                action: ""
            }))
        }, 1000)
    }, [ClearFormSet, navigate, dispatch])


    useEffect(() => {
        if (PageListData?.page_description !== null && pageList) {
            let defaultquestionContent = PageListData?.page_description;
            const questionContentState = ContentState.createFromBlockArray(
                convertFromHTML(defaultquestionContent?defaultquestionContent:"")
            );
            const questionEditorState = EditorState.createWithContent(questionContentState);
            setAnsEditorState(questionEditorState);
            const contentStateText = questionContentState.getPlainText();
            setCreatePage(pre => ({
                ...pre,
                page_description: contentStateText
            }))
        }
    }, [PageListData, LocationRoute]);

    const [faqAnswer, setFaqAnswer] = useState("")
    console.log(faqAnswer)
    const maxDescLength = 1024
    const onAnswerEditorChange = (newEditorState) => {
        if (charCount < maxDescLength) {

            setAnsEditorState(newEditorState);
            const contentState = newEditorState.getCurrentContent();
            const plainText = contentState.getPlainText("");
            const characterCount = plainText.length;
            setCharCount(characterCount);
            const contentAsRaw = convertToRaw(contentState);
            const htmlContent = draftToHtml(contentAsRaw);
            const styledHtmlContent = `<div style="font-family: sans-serif;">${htmlContent}</div>`;
            setFaqAnswer(styledHtmlContent);
            setCreatePage((prevInputValue) => ({
                ...prevInputValue,
                page_description: styledHtmlContent,
            }));
        }
    };


    function addMachine(e) {
        e.preventDefault()
        if(tabName === "about-us")
        if (createPage?.page_description !== "") {
            let param = {
                "transmit": "broadcast",
                "url": "page_edit",
                "request": {
                    "id": PageListData?.id,
                    "page_name": PageListData?.page_name,
                    "page_slug": PageListData?.page_slug,
                    "page_description": createPage?.page_description,
                    "is_active": PageListData?.is_active
                },
            }
            wsSend_request(websocket, param)
        } else {
            CheckValid(createPage?.page_description, { type: 'Description', error: setDesciptionError })
        }
    }

    useEffect(()=>{
        let param = {
            "transmit": "broadcast",
            "url": "page_list",
            "request": {
                "status": true,
                "limit": "20",
                "page_no": 1 ,
                "search": "",
                "order_by": "-updated_at"
            }
        }
        wsSend_request(websocket, param)
    },[])

    return (
        <>
            <div style={{ margin: "6px" }} className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12">
                                <Form.Group >
                                    <Form.Label>Description<span className="text-danger">*</span></Form.Label>
                                    <Editor
                                        editorState={questionEditorState}
                                        onEditorStateChange={onAnswerEditorChange}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        wrapperStyle={{
                                            width: "97%",
                                            border: "1px solid #ccc",
                                            backgroundColor: "white",
                                            padding: "10px",
                                            borderRadius: "4px",
                                        }}
                                        editorStyle={{
                                            minHeight: "100px",
                                            border: "1px solid #ccc",
                                            padding: "10px",
                                            borderRadius: "4px",
                                            fontFamily: "sans-serif",
                                        }}
                                        toolbar={{
                                            options: [
                                                "inline",
                                                "blockType",
                                                "fontSize",
                                                "list",
                                                "textAlign",
                                                "colorPicker",
                                                "link",
                                                "emoji",
                                                "history",
                                            ],
                                            inline: {
                                                inDropdown: false,
                                                options: [
                                                    "bold",
                                                    "italic",
                                                    "underline",
                                                    "strikethrough",
                                                    "superscript",
                                                    "subscript",
                                                ],
                                            },
                                            list: {
                                                inDropdown: false,
                                                options: ["unordered", "ordered"],
                                            },
                                            textAlign: {
                                                inDropdown: true,
                                            },
                                            link: { inDropdown: true },
                                            history: { inDropdown: false },
                                        }}
                                    />
                                    {errorDescription !== "" && <span className="text-danger">{errorDescription}</span>}
                                </Form.Group>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <button id="button" className="pt-2 ps-3 pe-3 pb-2 float-end mb-3 me-2" type="submit" onClick={addMachine}>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AboutPage;

